import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools }
    from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
// Yazdığımız Combine Reducer'ı dahil ediyoruz
import rootReducer from './reducers';
const initialState = {};
const middleware = [thunk];
// Aslında dikkat etmemiz gereken tek şey 2 parametre
// rootReducer olarak yazdığımız reducers/index.js
// initialState olarak başlangıç State'i
const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);
export default store;