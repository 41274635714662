import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import { SET_CURRENT_USER } from './types'
import { API_URL } from '../utils/constants';

// REGISTER
export const registerUser = (userData, history) => {
    axios.post('/api/v1/auth/register', userData)
        .then(res => history.push('/login'))
        .catch(err => console.log(err))
}

export const loginUser = userData => dispatch => {
    axios.post(API_URL + 'users/auth', userData)
        .then(res => {
            // Gelen token'i kullanmak için değişken olarak saklıyoruz        
            const token = res.data.token
            // Token'i localStorage'da saklıyoruz
            localStorage.setItem('jwtToken', token)
            // Daha önce yazdığımız fonksiyon ile gelecek istekler için        kaydediyoruz
            setAuthToken(token)
            // Token'i Çözümlüyoruz
            const decoded = jwt_decode(token)
            // ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
            dispatch(setCurrentUser(decoded))

            window.location = '/'
        })
        .catch(err => {
            alert("Hatalı e-posta veya şifre girdiniz.");
            console.log(err)})
}

// KULLANICIYI REDUCER'A GÖNDERİYORUZ
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded // Bilgi taşıyan kutular
    };
};