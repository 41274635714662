import sidebarBgImage from '../../assets/img/sidebar/sidebar-12.jpg';
import SourceLink from '../../components/SourceLink';
import React from 'react';

import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems = [
  { to: '/parties', name: 'Partiler', icon: 'account_balance_wallet', exact: false },
  { to: '/politicians', name: 'Politikacılar', icon: 'supervised_user_circle', exact: false },
  { to: '/polls', name: 'Seçimler', icon: 'poll', exact: false },
  { to: '/promisetypes', name: 'Vaat Tipi', icon: 'layers', exact: false },
  { to: '/polltypes', name: 'Seçim Tipleri', icon: 'hvac', exact: false },
  { to: '/cities', name: 'Şehirler', icon: 'map', exact: false }
];

const addItems = [];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <span className="text-white">
                Seçim Vaat
              </span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}>
                  <span className="material-icons"> {icon}</span>
                  <span>{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
