import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

//import setAuthToken from '../utils/setAuthToken'


const jwtToken = localStorage.getItem("jwtToken");
/*if (jwtToken) {
    setAuthToken(jwtToken);
}*/
if (!jwtToken && window.location.pathname != "/login") {
    window.location = '/login'
}
ReactDOM.render(<App />, document.getElementById('root'));
