import { API_URL } from '../utils/constants';
import axios from "axios";
const jwtToken = localStorage.getItem("jwtToken");

var axiosApiInstance = axios.create();
  /*{
  baseURL: API_URL,
  responseType: "json",
  headers: {
    'Authorization': 'Bearer ' + jwtToken
  }
});*/

// Request interceptor for API calls

axiosApiInstance.interceptors.request.use(
  async config => {
    config.baseURL = API_URL;
    config.responseType = "json";
    config.headers = {
      'Authorization': `Bearer ${jwtToken}`,
      'Accept': 'application/json'
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    window.location = '/login'
  }
  if (error.response.status === 403 && !originalRequest._retry) {
    /* originalRequest._retry = true;
     const access_token = await refreshAccessToken();            
     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;*/
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});

export default axiosApiInstance;