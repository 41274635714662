import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import LoginPage from './pages/LoginPage';

import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store'
import './styles/reduction.scss';


const CitiesPage = React.lazy(() => import('./pages/CitiesPage'));
const PoliticiansPage = React.lazy(() => import('./pages/PoliticiansPage'));
const PoliticianCrudPage = React.lazy(() => import('./pages/PoliticianCrudPage'));
const PollTypesPage = React.lazy(() => import('./pages/PollTypesPage'));
const PartiesPage = React.lazy(() => import('./pages/PartiesPage'));
const PartyCrudPage = React.lazy(() => import('./pages/PartyCrudPage'));
const PollsPage = React.lazy(() => import('./pages/PollsPage'));
const PollCrudPage = React.lazy(() => import('./pages/PollCrudPage'));
const PromiseTypesPage = React.lazy(() => import('./pages/PromiseTypesPage'));
const PromisesPage = React.lazy(() => import('./pages/PromisesPage'));
const PromiseCrudPage = React.lazy(() => import('./pages/PromiseCrudPage'));
const FeedCrudPage = React.lazy(() => import('./pages/FeedCrudPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={getBasename()}>
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path="/login"
                layout={EmptyLayout}
                component={props => (
                  <LoginPage {...props} />
                )}
              />*/
              <LayoutRoute
                exact
                path="/signup"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_SIGNUP} />
                )}
              />

              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Route exact path="/" component={PromisesPage} />
                  <Route exact path="/promisetypes" component={PromiseTypesPage} />
                  <Route exact path="/polltypes" component={PollTypesPage} />
                  <Route exact path="/cities" component={CitiesPage} />

                  <Route exact path="/politicians" component={PoliticiansPage} />
                  <Route exact path="/politicians/add" component={PoliticianCrudPage} />
                  <Route exact path="/politicians/:operation/:id" component={PoliticianCrudPage} />

                  <Route exact path="/parties" component={PartiesPage} />
                  <Route exact path="/parties/add" component={PartyCrudPage} />
                  <Route exact path="/parties/:operation/:id" component={PartyCrudPage} />

                  <Route exact path="/polls" component={PollsPage} />
                  <Route exact path="/polls/add" component={PollCrudPage} />
                  <Route exact path="/polls/:operation/:id" component={PollCrudPage} />

                  <Route exact path="/promises" component={PromisesPage} />                  
                  <Route exact path="/promises/:operation/:id" component={PromiseCrudPage} />

                  <Route exact path="/feeds/:page/:operation/:id" component={FeedCrudPage} />
                </React.Suspense>
              </MainLayout>
              <Redirect to="/" />
            </Switch>
          </GAListener>
        </BrowserRouter>
      </Provider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
