import { SET_CURRENT_USER } from '../actions/types';
// Başlangıçta ihtiyacımız olan değiştirmek için iki başlangıç değeri var
// Arayüz'de yetkilendirme yapabilmemiz için isAuthenticated ve giriş yapan kullanıcı
const initialState = {
    isAuthenticated: false,
    user: {}
};
// Dispatch edilen Action'un Type'ını alıyoruz
// 1 Reducer dosyasında birden fazla Action'a göre işlem 
// yapabilmemiz için Switch Case kullanmamız lazım
// Sözlü bir anlatıma çevirirsek;
//Dispatch edilen Action'un Type'ı Case'lerden hangisi ise onu
// Return edecek
// Hiç biri değil ise Default Case dönecek ve parametre geçtiğimiz // Initial State'i Return edecek
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            };
        default:
            return state;
    }
}