import { CDN_URL } from '../utils/constants';
import React from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button
} from 'reactstrap';

import UserService from '../services/UserService';
import { loginUser } from '../actions/authActions'
import { connect } from 'react-redux'


class LoginPage extends React.Component {

  constructor() {
    super();
    this.state = {
      error: null,
      isLoaded: false,
      party: null
    }
  }



  async submitForm(e) {
    e.preventDefault();
    this.setState({ error: "" })
    const data = new FormData(e.target);
    const result = Object.fromEntries(data);  
    if (result.email == "") this.setState({ error: "* E-mail zorunludur." });
    else {

      try {
        var model = {
          email: result.email,
          password: result.password
        };
        this.props.loginUser(model)
/*
        const response = await UserService.Auth(model)

        if (response.data != null && response.data.token != "") {

          console.log(response.data.token);
          alert("Giriş Başarılı:" + response.data.token)
        } else {
          alert("Hatalı kullanıcı");
        }
*/

      }
      catch (error) {
        this.setState({ error: "Hata: " + error })
      }
    }

  }



  handleAuthState = authState => {
  };

  async componentDidMount() {
  }



  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Col md={6} lg={4}>
          <Card>
            <CardHeader>
              <h2 className="text-center">Seçim Vaat</h2>
            </CardHeader>
            <CardBody>

              <Form onSubmit={(e) => this.submitForm(e)}>
                <FormGroup>
                  <Label>E-mail</Label>
                  <Input
                    required
                    type="email"
                    name="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Şifre</Label>
                  <Input
                    required
                    type="password"
                    name="password"
                  />
                </FormGroup>

                <hr />
                <Button
                  size="lg"
                  className="bg-gradient-theme-left border-0"
                  block
                  type="submit"
                >
                  Giriş Yap
              </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps, { loginUser })(LoginPage)
